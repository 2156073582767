import styled from "styled-components";

export const Wraper=styled.div`
z-index: 10;
position: fixed;
width: 100%;
display: flex;
justify-content: flex-start;
bottom: 10%;

.baseBox{
    display: flex;
    flex-direction: row;
    align-items: bottom;
    border-radius: 10px;
    margin-right: 20px;
    margin-left:10px ;
}
.baseNum{
    font-weight: 700;
    font-size: 100px;
    line-height: 1;
    color: white;
    opacity: 0.6;
}
@media (max-width:801px) {
    &{
            /* left:0px;
            top:50%;
            transform:translate(0,-50%) ;
            justify-content:center ; */
            top:10px ;
            .baseNum{
            font-size:90px;
            margin-right:5px;
            }
            .baseBox{
                margin-right: 10px;
            }
            .baseTxt{
                font-size:11px;
            }

    }
}
@media (max-width:480px) {
        &{ 
           position: absolute;
           top:68%;
          {/*position: relative;*/
           justify-content:flex-start ;
            .baseNum{
            font-size:40px;
            margin-right:-6px;
            }
            .baseBox{
                margin-right:-12px ;

            }
        }
}
`