import styled from "styled-components";

export const Wraper=styled.footer`
padding: 20px;

.KQlogo{
    position: absolute;
    top: 0px;
    right: 0px;
}
.itemone{
    position: absolute;
    top: 0px;
    left: 0px;
}
.itemtwo{
    position: absolute;
    top: 0px;
    left: 200px;
}
.itemthree{
    position: absolute;
    top: 0px;
    left: 380px;
}
.socialMedia{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 50px;
    max-width: 250px;
    margin-top: 25px;
    z-index: 5;
}
svg:hover path {
    fill: #D29F1B;
    transition: fill 0.5s ease-in-out;
}
svg:hover circle {
    fill: #D29F1B;
    transition: fill 0.5s ease-in-out;
}


@media (max-width:941px) {
    &{
        /* .itemone{
            display: none;
        }
        .itemtwo{
            display: none;
        } */
        .itemthree{
            display: none;
        }
       
    }
}
@media (max-width:801px) {
    &{
        .KQlogo{
          display: none;
        }
    }
}
@media (max-width:480px) {
    &{
        padding:10px;
        .socialMedia{
            width: 100%;
            max-width: 100%;
            justify-content: space-between;
        }
        .itemone{
            display: none;
        }
        .itemtwo{
            display: none;
        }
      
    }
}
`
export const Egyverse=styled.div`
position: relative;
width: 505px;
height: 95px;
.egy-sm{
    font-family: Indira K;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 36px;
    z-index: 2;
    position: absolute;
    left: 10px;
    top: 50%;
   transform: translate(0,-50%);
    
}
.egy-lg{
 
    font-family: Indira K;
    color: #47453D;
    font-style: normal;
    font-weight: bold;
    font-size: 86px;
    line-height: 36px;
    z-index: 1;
    position: absolute;
    left: 0px;
    top: 50%;
   transform: translate(0,-50%);
}
@media (max-width:941px){
}
@media (max-width:801px) {
    &{
       .egy-lg{
           font-size: 66px;
       }
       .egy-sm{
           font-size: 36px;
       }
    }
}
@media (max-width:480px) {
    &{
        display:none ;
        width: 100%;
       .egy-lg{
          
       }
       .egy-sm{
           font-size: 36px;
           left: 50%;
        
           transform: translate(-50%,-50%);
       }
    }
}
`