import React,{useState,useEffect} from 'react'
import {Wraper} from './CountDown.styles'
import { useCountdown } from './CustomTimer';

function CountDown() {
    const [days, hours, minutes, seconds] = useCountdown("June 01 2022 00:00:00");

  return (
      <Wraper>
    <div className='baseBox' id='dy'>
        <span className='baseNum'>{days}</span>
        <span className='baseTxt'>days</span>
    </div>
    <div className='baseBox' id='hr'>
    <span className='baseNum'>{hours}</span>
        <span className='baseTxt'>hours</span>
    </div>
    <div className='baseBox' id='min'>
    <span className='baseNum'>{minutes}</span>
        <span className='baseTxt'>mins</span>
    </div>
    <div className='baseBox' id='sc'>
    <span className='baseNum'>{seconds}</span>
        <span className='baseTxt'>secs</span>
    </div>
      </Wraper>
  )
}

export default CountDown