import styled from "styled-components";

export const Wraper=styled.div`
margin-bottom:50px ;
.socialMedia{
    display: flex;
    align-items: center;
    justify-content:space-between ;
    padding:10px ;
}
svg{
    margin-right:5px;
}
svg:hover path {
    fill: #D29F1B;
    transition: fill 0.5s ease-in-out;

}
svg:hover circle {
    fill: #D29F1B;
    transition: fill 0.5s ease-in-out;
}


@media (max-width:941px) {
   
}

@media (max-width:480px) {
   
}
`
