import React from 'react'
import {Wraper,MovingElmNumOne,MovingElmNumTwo,MainBG,Mint,Message} from './Main.styles'
import bg from '../../assets/bg.gif'
import logo from '../../assets/logo.png'
import CountDown from '../timer/CountDown'
import Typewriter from 'typewriter-effect';
import Social from '../social/Social'

function Main() {
  return (
    <Wraper>
        {/* Moving elements #1 Wraper*/}
        <MovingElmNumOne>
            <span className='MovingElmToplef'>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
           
            </span>
            <span className='MovingElmToprt'>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
           
            </span>
            <span className='MovingElmTopmd'>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
           
            </span>
        </MovingElmNumOne>

        {/* Main Background  */}
        <MainBG>
            <div className='bg'>

            <img src={bg} alt='bg' />
            </div>
            <div className='campaign'>
                <span className='header_campaign'> 
                    <Typewriter
                    options={{strings: ['EGYVERSE','CHAPTER 1','INTO THE METAVERSE'],
                    autoStart: true,
                    loop: true,
                    }}/>
                </span>
            </div>
            {/*<CountDown/>*/}
            <Social/>
        </MainBG>

        {/* Moving elements #1 Wraper...*/}
        <MovingElmNumTwo>
            <div className='MovingElmBottomlef'>
            <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
           
            </div>
            <div className='MovingElmBottomrt'>
            <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
           
            </div>
            <div className='MovingElmBottommd'>
            <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
                <span>egyverse.eth</span>
           
            </div>
        </MovingElmNumTwo>
        {/* Minting Button */}
        <Mint>
            <div className='mainbtn'>
                <div className='info'>
                    <span>Floor Price</span>
                    <span>50 matic</span>
                </div>
                <div className='info'>
                    <span>Remaining time</span>
                    <span>50H:30M:20S</span>
                </div>
                <button className='btn'>MINT</button>
            </div>
        </Mint>
    </Wraper>
  )
}

export default Main