import './App.css';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Main from './components/main/Main';
import Social from './components/social/Social';
function App() {
  return (
    <div className="App">
    {/*<Header/>*/}
    <Main/>
    {/* <Social/>     */}
    {/*<Footer/>*/}
    </div>
  );
}

export default App;
