import styled from 'styled-components'
const speed=150;
export const Wraper=styled.main`
width: 100%;
`


export const MovingElmNumOne=styled.div`
width: 100%;
background-color: #D29F1B;
min-height: 40px;
display: flex;
align-items: center;
position: fixed;
bottom:0;
overflow: hidden;
z-index: 2;
font-size: 22px;
box-shadow: 29px -4px 40px 19px rgba(210, 159, 27, 0.32);
.MovingElmToplef{
   position: fixed;
   display: inline-block;
   white-space: nowrap;
   overflow: hidden;

    animation: ${speed}s linear 0s infinite normal none running movingelmtoplef;
    span{
        margin-left: 10px;
      
    }
}

@keyframes movingelmtoplef {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0%);
    }
 }
.MovingElmToprt{
    position: absolute;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;

    animation: ${speed}s linear 0s infinite normal none running movingelmtoprt;
    span{
        margin-left: 10px;
      
    }
}

@keyframes movingelmtoprt {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(100%);
    }
 }
.MovingElmTopmd{
    position: absolute;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    animation: ${speed}s linear 0s infinite normal none running movingelmtopmd;
    span{
        margin-left: 10px;
        
    }
}

@keyframes movingelmtopmd {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(200%);
    }
 }
    @media (max-width:801px) {
        &{
            min-height: 30px;
        }
    }
    @media (max-width:480px) {
        &{
            font-size: 15px;
        }
    }
`
export const MovingElmNumTwo=styled.div`
width: 100%;
background-color: #D29F1B;
min-height: 40px;
display: flex;
position: fixed;
top:0;
overflow: hidden;
align-items: center;
z-index: 2;
font-size: 22px;

box-shadow: -29px 4px 40px 19px rgba(210, 159, 27, 0.32);

.MovingElmBottomlef{
    position: absolute;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    animation: ${speed}s linear 0s infinite normal none running movingelmbtmlef;
    span{
        margin-left: 10px;
    }
}
@keyframes movingelmbtmlef {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
 }
.MovingElmBottomrt{
    position: absolute;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    animation: ${speed}s linear 0s infinite normal none running movingelmbtmrt;
    span{
        margin-left: 10px;
    }
}
@keyframes movingelmbtmrt {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0%);
    }
 }
.MovingElmBottommd{
    position: absolute;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    animation: ${speed}s linear 0s infinite normal none running movingelmbtmmd;
    span{
        margin-left: 10px;
    }
}
@keyframes movingelmbtmmd {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(-200%);
    }
 }
 @media (max-width:801px) {
        &{
            min-height: 30px;
        }
    }
    @media (max-width:480px) {
        &{
            font-size: 15px;
        }
    }
`
export const MainBG=styled.div`
width: 100%;
height:100vh ;
display:flex ;
flex-direction:column;
align-items:center;
justify-content:space-between ;

.bg{    
  width:300px;
    img{
        width:100% ;
    }
}


.campaign{
   font-size:50px ;
}


@media (max-width:501px) {
      &{
        .campaign{
        font-size:30px ;
        }
      }
}

`


export const Mint=styled.div`
padding: 20px;
display: flex;
align-items: center;
display:none ;
.mainbtn{
    z-index: 2;
    display: flex;
    padding: 20px;
    flex: 0.4;
    justify-content: space-between;
    .info{
        display: flex;
        flex-direction: column;
        padding: 10px;
       span{
           margin-bottom: 5px;
       }
    }
    .btn{
        padding: 0px 20px;
        border-radius: 20px;
        border: 2px solid #D29F1B;        
        cursor: pointer;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 141.69%;
        color: #FFFFFF;
        background: rgba(210, 159, 27, 0.57);
        margin-left: 5px;
    }
    background: linear-gradient(91.03deg, rgba(255, 254, 254, 0.23) -47.11%, rgba(255, 254, 254, 0.23) 110.32%);
    backdrop-filter: blur(30px);
    border-radius: 5px;
    flex-wrap: wrap;
}
.KQ{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    flex: 0.6;
    span{
        font-family: Indira K;
        color: #47453D;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 36px;
        z-index: 1;
        margin:10px 30px;    
    }
}

@media (max-width:801px) {
        &{
            justify-content: center;
            .KQ{
                display: none;
            }
            .mainbtn{
                flex: 1;
                justify-content: space-evenly;
            }
        }
    }
    @media (max-width:480px) {
        &{
            .mainbtn{
                flex: 1;
            }
            .mainbtn .btn{
                flex: 100%;
                padding:5px 0px;
            }
        }
    }
`

export const Message=styled.div`
padding: 20px;
display: flex;
align-items: center;
justify-content: space-between;
min-height: 980px;
position: relative;
.ourmsg{
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
}
.main_msg{
    display: flex;
    flex-direction: column;
    margin-left: 50px;
}
.header{
    font-weight: 500;
    font-size: 72px;
    line-height: 85px;
    max-width: 354px;

}
.content{
    font-size: 18px;
    line-height: 27px;
    max-width: 354px;
}
.logo{
z-index: 1;
position: absolute;
}
.ingo_msg{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

}
.content_msg{
    display: flex;
    flex-direction: column;
    margin: 7px;
    margin-left: 10px;
}
.header_msg{
    width: 100%;
    display: flex;
    align-items: center;
}
.body_msg{
    max-width: 350px;
    span{
    font-size: 18px;
    line-height: 27px;
    }
}
#num_msg{
    font-family: Rubik;
    font-weight: 500;
    font-size: 48px;
    line-height: 57px;
    margin-right: 5px;
    color: rgba(255, 255, 255, 0.29);
}
#content_header{
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
}
@media (max-width:801px) {
        &{
            flex-direction: column;
            .ourmsg{
                flex-direction: column;
                align-items: center;
            }
            .ingo_msg{
                justify-content: center;
                margin-top: 20px;
            }
           
            .main_msg{
                margin-left: 0px;
            }
            .ingo_msg{
                flex-wrap: nowrap;
                flex-direction: column;
            }
        }
    }
    @media (max-width:480px) {
        &{
            .header{
            font-weight: 500;
            font-size: 50px;
            line-height: 85px;
            max-width: 354px;
            }
            .logo{
                display: none;
            }
        }
    }
`