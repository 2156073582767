import styled from 'styled-components'

export const Wraper=styled.header`
display: flex;
justify-content: space-between;
padding: 10px;
align-items: center;
.socialMedia{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 50px;
    min-width: 250px;
    max-width:300px ;
    margin-top: 25px;
    z-index: 5;
  
}
svg:hover path {
    fill: #D29F1B;
    transition: fill 0.5s ease-in-out;
}
svg:hover circle {
    fill: #D29F1B;
    transition: fill 0.5s ease-in-out;
}
@media (max-width:801px) {
    &{
        padding: 5px;
        .socialMedia{
            display:none ;
        }
    }
}
@media (max-width:480px) {
    &{
        justify-content:center;
       
    }
}
`
export const Egyverse=styled.div`
min-height: 100px;
position: relative;
.egy-sm{
    font-family: Indira K;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 36px;
    z-index: 2;
    position: absolute;
    left: 10px;
    top: 50%;
   transform: translate(0,-50%);
    
}
.egy-lg{
 
    font-family: Indira K;
    color: #47453D;
    font-style: normal;
    font-weight: bold;
    font-size: 86px;
    line-height: 36px;
    z-index: 1;
    position: absolute;
    left: 0px;
    top: 50%;
   transform: translate(0,-50%);
}
@media (max-width:801px) {
    &{
       .egy-lg{
           font-size: 66px;
       }
       .egy-sm{
           font-size: 36px;
       }
    }
}
@media (max-width:480px) {
    &{
       .egy-lg{
           display: none;
       }
       .egy-sm{
           font-size: 36px;
           left: 50%;
           transform: translate(-50%,-50%);
       }
    }
}
`